/* Global Styles */
:root {
	--dark-color: #303030;
	--darker-color: #292929;
	--grey-color: #999999;
	--light-grey-color: #c2c2c2;
	--light-color: #f7f7f7;
	--danger-color: #dc3545;
	--success-color: #28a745;
	--white-color: #ffffff;
	--primary-color: #16e0bd;

	--primary-color-opacity: rgba(22, 224, 189, 0.6);

	--transition: all 0.3s linear;
	--spacing: 0.2rem;
	--radius: 0.25rem;

	--fw-reg: 300;
	--fw-bold: 900;

	--fs-h1: 3rem;
	--fs-h2: 1.5rem;
	--fs-h3: 1.25rem;
	--fs-body: 1rem;

	--bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
		0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Poppins', sans-serif;
}

.header {
	width: 100%;
	height: 100vh;
	background: var(--dark-color);
}

.navbar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 5rem;
	display: flex;
	align-items: center;
	z-index: 200;
	background: var(--dark-color);
	color: var(--white-color);
}
.nav-center {
	width: 90vw;
	max-width: 1170px;
	margin: 0 auto;
}
.nav-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.toggle-btn {
	font-size: 2rem;
	background: transparent;
	border-color: transparent;
	color: var(--white-color);
	cursor: pointer;
	transition: var(--transition);
}
.toggle-btn:hover {
	color: var(--primary-color);
}
.nav-links {
	display: none;
}
@media screen and (min-width: 768px) {
	.toggle-btn {
		display: none;
	}
	.nav-links {
		display: flex;
		justify-content: flex-end;
	}

	.page-links {
		text-decoration: none;
	}

	.nav-links li {
		margin-right: 2rem;
		list-style-type: none;
	}
	.nav-links a {
		text-transform: capitalize;
		color: var(--light-color);
		font-weight: bold;
		text-decoration: none;
		overflow: hidden;
		letter-spacing: var(--spacing);
		transition: var(--transition);
		padding: 0.2rem 0;
	}
	.nav-links a:hover {
		color: var(--primary-color);
		box-shadow: 0px 2px var(--primary-color);
	}
	.nav-center {
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;
	}
}

@media screen and (min-width: 992px) {
	.navbar {
		background: transparent;
	}
}
/*
=============== 
Sidebar
===============
*/
.sidebar {
	background: var(--dark-color);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: grid;
	place-items: center;
	opacity: 0;
	transition: var(--transition);
	transform: translateX(-100%);
}
.show-sidebar {
	opacity: 1;
	transform: translateX(0);
}
.sidebar-links li {
	opacity: 0;
	list-style-type: none;
}
.sidebar-links li a {
	display: block;
	text-align: center;
	text-transform: capitalize;
	color: var(--light-color);
	letter-spacing: var(--spacing);
	margin-bottom: 0.5rem;
	font-size: 2rem;
	text-decoration: none;
	transition: var(--transition);
	border-radius: var(--radius);
}
.sidebar-links li a:hover {
	background: var(--dark-color);
	color: var(--primary-color);
}
.close-btn {
	position: absolute;
	right: 4.75%;
	top: 2.75%;
	font-size: 2.5rem;
	background: transparent;
	border-color: transparent;
	color: var(--primary-color);
	cursor: pointer;
}
@media screen and (min-width: 992px) {
	.sidebar {
		transform: translateX(-100%);
	}
}
/*
=============== 
Sidebar Animation
===============
*/
.sidebar-links li {
	animation: slideRight 0.5s ease-in-out 0.3s forwards;
}
.sidebar-links li:nth-of-type(1) {
	animation-delay: 0.25s;
}
.sidebar-links li:nth-of-type(2) {
	animation-delay: 0.5s;
}
.sidebar-links li:nth-of-type(3) {
	animation-delay: 0.75s;
}
.sidebar-links li:nth-of-type(4) {
	animation-delay: 1s;
}
.sidebar-links li:nth-of-type(5) {
	animation-delay: 1.25s;
}
@keyframes slideRight {
	0% {
		transform: translateX(-200px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
.sidebar-icons li {
	opacity: 0;
	animation: slideUp 0.5s ease-in-out 0.3s forwards;
}
.sidebar-icons li:nth-of-type(1) {
	animation-delay: 0.25s;
}
.sidebar-icons li:nth-of-type(2) {
	animation-delay: 0.5s;
}
.sidebar-icons li:nth-of-type(3) {
	animation-delay: 0.75s;
}
.sidebar-icons li:nth-of-type(4) {
	animation-delay: 1s;
}
.sidebar-icons li:nth-of-type(5) {
	animation-delay: 1.25s;
}
@keyframes slideUp {
	0% {
		transform: translateY(200px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/* Helpers */
.container {
	width: 1200px;
	margin: 0 auto;
}

.pt {
	padding-top: 1em;
}

/* Grid System */
.row {
	display: flex;
	width: 100%;
}

.col-3 {
	width: 25%;
}

.col-3-about {
	width: 25%;
	margin: 0 auto;
}

.col-6 {
	width: 50%;
}

.col-6-hide {
	width: 50%;
}

.col-9 {
	max-width: 75%;
}

.col-9-about {
	max-width: 75%;
}

.grid-4 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1rem;
}

.grid-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 1rem;
}

.grid-2-big {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 1rem;
}

.margin-top {
	padding-top: 100px;
}

/* Header */
.header-content {
	display: flex;
	align-items: center;
	height: 100vh;
	width: 100%;
	color: var(--white-color);
}

.header-ul a {
	color: #ffffff;
}

.header-ul {
	list-style-type: none;
	display: flex;
	margin-bottom: 20px;
}

.header-ul li {
	width: 50px;
	height: 50px;
	border: 2px solid var(--primary-color);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding-top: 5px;
	font-size: 20px;
	transition: all 0.2s ease-in-out;
}

.header-ul li:hover {
	transform: scale(1.2);
}

.header-section {
	margin: 0 auto;
}

.header-section h1 {
	font-size: var(--fs-h1);
	font-weight: 700;
	margin-bottom: 20px;
	text-align: center;
}

.header-section p {
	font-size: var(--fs-h3);
	font-weight: 300;
	text-align: center;
	line-height: 40px;
	margin-bottom: 50px;
}

.header-button {
	align-items: center;
	text-align: center;
	transition: all 0.2s ease-in-out;
}

.project-button {
	max-width: 500px;
	margin: 0 auto;
}

.btn {
	outline: none;
	border: none;
	color: var(--white-color);
	padding: 0.5rem 1.3rem;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.5em;
	align-content: center;
}

.header-button:hover {
	transform: scale(1.1);
}

.btn-outline {
	border: 2px solid var(--primary-color);
	border-radius: 25px;
}

.img-section {
	margin: 0 auto;
}

.banner-img {
	max-width: 500px;
	max-height: 500px;
	border-radius: 100%;
	border: 2px solid var(--primary-color);
	box-shadow: var(--bs);
}

/* Services */
.myServices {
	background-color: var(--darker-color);
	color: var(--white-color);
	text-align: center;
	width: 100%;
}

.sectiontitle {
	padding: 1.5em;
}

.sectionTitle-Services:after {
	content: 'Services';
	font-size: var(--fs-h1);
	border-bottom: 3px solid #16e0bd;
	margin-bottom: 10px;
}

.services {
	margin: 0px 60px;
}

.card {
	/*background-color: transparent;*/
	background-color: var(--dark-color);
	max-width: 400px;
	height: 300px;
	margin: 50px auto;
	border: 1px solid #ffffff27;
	transition: all 0.2s ease-in-out;
}

.card:hover {
	transform: scale(1.1);
	background-color: var(--primary-color-opacity);
}

.card-inner {
	width: 100%;
	height: 100%;
	padding: 20px 0;
	box-shadow: var(--bs);
	opacity: 0.8;
}

.card-title {
	font-weight: var(--fw-bold);
	color: var(--white-color);
	padding-top: 15px;
}

.card-skills {
	padding-top: 10px;
	font-size: var(--fs-body);
}

.card-icon i {
	color: var(--white-color);
}

hr {
	margin: 10px 30px;
}

/* Experience */

.myExperience {
	background-color: var(--dark-color);
	color: var(--white-color);
	text-align: center;
	width: 100%;
	max-height: 1200px;
	padding-bottom: 40px;
}

.sectionTitle-Experience:after {
	content: 'Experience';
	font-size: var(--fs-h1);
	border-bottom: 3px solid #16e0bd;
	margin-bottom: 10px;
}

.experience-container {
	width: 1500px;
	margin: 0 auto;
}

.experience {
	margin: 0px 60px;
}

.experience-button-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 200px;
	color: var(--white-color);
}

.button-section {
	display: flex;
	margin: auto;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.job-btn {
	margin-left: 20px;
}

.button-section button {
	outline: none;
	border: none;
	background-color: inherit;
	color: var(--white-color);
	padding: 0.5rem 1.3rem;
	margin: 2rem auto;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 700;
	font-size: var(--fs-h3);
	letter-spacing: 0.5em;
	align-content: center;
	transition: 0.2s ease-in-out;
	cursor: pointer;
}

.button-section button:hover {
	transform: scale(1.1);
}

.jobs-button-border {
	border-left: 2px solid var(--primary-color);
}

.experience-content {
	display: grid;
	margin: 0 auto;
	line-height: 2.75em;
}

.job-section {
	text-align: left;
	font-weight: 500;
}

.job-section h2 {
	font-size: 2em;
	font-weight: 500;
}

.job-section h3 {
	font-weight: 700;
	letter-spacing: 4px;
	font-size: 1.35em;
	text-transform: uppercase;
}

.jobtitle {
	margin-top: 5px;
}

.jobtitle span {
	background-color: var(--primary-color);
	padding: 0.25em 0.75em;
	border-radius: 10px;
}

.job-section p {
	font-size: 1.1em;
	margin-bottom: 0.7em;
}

.jobdesc span {
	color: #16e0bd;
	font-size: 1.2em;
}

/* Projects */

.myProjects {
	background-color: var(--darker-color);
	color: var(--white-color);
	text-align: center;
	width: 100%;
	padding-bottom: 40px;
}

.sectionTitle-Projects:after {
	content: 'Latest Projects';
	font-size: var(--fs-h1);
	border-bottom: 3px solid #16e0bd;
	margin-bottom: 10px;
}

.sectionTitle-Projectspage:after {
	content: 'Projects';
	font-size: var(--fs-h1);
	border-bottom: 3px solid #16e0bd;
	margin-bottom: 10px;
}

.project-container {
	margin: 0 auto;
}

.flip-card {
	background-color: transparent;
	width: 700px;
	height: 500px;
	margin: 0 auto;
	border: transparent;
	position: relative;
	perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.9s;
	transform-style: preserve-3d;
}

.flip-card-front {
	background-color: #ffffff;
	color: black;
	box-shadow: var(--bs);
}

.flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back-one,
.flip-card-back-two,
.flip-card-back-three {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden; /* Safari */
	backface-visibility: hidden;
}

.flip-card-description {
	margin: 40px auto;
}

.project-header {
	color: var(--primary-color);
	margin-bottom: 30px;
	font-size: 30px;
}

.project-desc {
	font-size: var(--fs-h3);
	margin: 0 30px 50px 50px;
}

.project-stack {
	letter-spacing: 4px;
	text-transform: uppercase;
	margin: 15px auto;
	color: var(--primary-color);
}

.flip-card-ul {
	list-style-type: none;
	display: flex;
	margin: 20px auto;
	width: 20%;
}

.flip-card-ul li {
	width: 40px;
	height: 40px;
	color: var(--white-color);
	border: 2px solid var(--primary-color);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding-top: 5px;
	font-size: 20px;
	transition: all 0.2s ease-in-out;
}

.flip-card-ul li:hover {
	transform: scale(1.2);
	color: var(--primary-color);
}

.project-link {
	color: var(--white-color);
	cursor: pointer;
}

.project-link:hover {
	color: var(--primary-color);
}

.flip-card-background-one img {
	object-fit: cover;
	width: 700px;
	height: 500px;
}

.flip-card-back-one {
	background-color: var(--dark-color);
	color: white;
	transform: rotateY(180deg);
}

/* About me */

.aboutMe {
	background-color: var(--dark-color);
	color: var(--white-color);
	min-height: 100vh;
}

.about-container {
	max-width: 1400px;
	padding-top: 200px;
	margin: 0 auto;
}

.aboutMeImg {
	box-shadow: var(--bs);
	max-width: 350px;
	border-bottom: 3px solid var(--primary-color);
}

.about-content {
	margin: 0 auto;
	text-align: center;
	max-width: 750px;
}

.sectionTitle-About:after {
	content: 'About me';
	font-size: var(--fs-h1);
	border-bottom: 3px solid var(--grey-color);
	margin-bottom: 20px;
}

.abouttitle,
.abouttitletwo {
	margin: 20px auto;
}

.abouttitle span,
.abouttitletwo span {
	background-color: var(--primary-color);
	font-size: 1.2em;
	padding: 0.25em 0.75em;
	border-radius: 10px;
}

.tools-row {
	width: 100%;
	margin: 10px auto;
	font-size: 2.5em;
	color: var(--grey-color);
}

.tools span {
	margin: 0 50px;
}

.tools {
	margin: 10px auto;
}

/* Contact */

.contact-page {
	background: var(--dark-color);
	min-height: calc(100vh - 180px);
}

.contact-page {
	display: grid;
	place-items: center;
	padding: 5rem 0;
}
.contact-form {
	background: var(--light-color);
	border-radius: var(--radius);
	text-align: center;
	box-shadow: var(--bs);
	transition: var(--transition);
	width: 90vw;
	max-width: 35rem;
}
.contact-form:hover {
	box-shadow: var(--dark-shadow);
}
.contact-form h3 {
	padding-top: 1.25rem;
	color: var(--primary-color);
}
.form-group {
	padding: 1rem 1.5rem;
}
.form-control {
	display: block;
	width: 100%;
	padding: 0.75rem 1rem;
	border: none;
	margin-bottom: 1.25rem;
	background: var(--light-grey-color);
	border-radius: var(--radius);
	text-transform: uppercase;
	letter-spacing: var(--spacing);
}
.form-control::placeholder {
	font-family: var(--ff-primary);
	color: var(--clr-grey-1);
	text-transform: uppercase;
	letter-spacing: var(--spacing);
}
.submit-btn {
	display: block;
	width: 100%;
	padding: 1rem;
	border-bottom-left-radius: var(--radius);
	border-bottom-right-radius: var(--radius);
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	background: var(--primary-color);
}

/*
  Footer

  */

.footer {
	background-color: #303030;
	color: var(--white-color);
	text-align: center;
	padding: 1em;
	font-size: var(--fs-h3);
}

.footer a {
	color: var(--white-color);
	text-decoration: none;
}

.footer p {
	color: var(--primary-color);
}

.footerLink {
	font-weight: var(--fw-bold);
}

.footerLink:hover {
	color: var(--primary-color);
}

.footer-ul {
	list-style-type: none;
	display: flex;
	margin: 20px auto;
	width: 20%;
}

.footer-ul li {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding-top: 5px;
	font-size: 20px;
	transition: all 0.2s ease-in-out;
}

.footer-ul li:hover {
	transform: scale(1.2);
}

.footer-ul li a:hover {
	color: var(--primary-color);
}

.toggle {
	position: fixed;
	top: 20px;
	right: 50px;
	color: #16e0bd;
	width: 50px;
	height: 50px;
	font-size: 1.5em;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	display: none;
}

/* Responsive Query's */

@media (max-width: 1600px) {
	.grid-4 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
	}

	.grid-2-big {
		display: grid;
		grid-template-columns: none;
		grid-gap: none;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
	}

	.card-title {
		font-size: 1.3em;
		padding-top: 10px;
	}
}

@media (max-width: 1520px) {
	.container {
		width: 900px;
		margin: 0 auto;
	}

	.banner-img {
		max-width: 300px;
		max-height: 300px;
	}

	.header-section h1 {
		font-size: 2.25em;
	}

	.header-section p {
		font-size: var(--fs-h3);
	}

	.experience-container {
		margin: 0 auto;
		width: 100%;
	}

	.job-section h2 {
		font-size: 1.8em;
	}

	.job-section h3 {
		font-size: 1.3em;
	}

	.job-section p {
		font-size: 1em;
	}

	.jobdesc span {
		color: #16e0bd;
		font-size: 1.2em;
	}

	.col-3 {
		width: 100%;
	}

	.button-section {
		display: flex;
		align-items: center;
	}

	.job-btn {
		margin-left: 20px;
	}

	.col-9 {
		width: 100%;
	}

	.experience-content {
		display: grid;
	}

	.col-9-about {
		width: 50%;
		margin: 0 auto;
	}
}

@media (max-width: 1050px) {
	.container {
		width: 100%;
		padding: 0 50px;
	}

	.col-6 {
		width: 100%;
	}

	.col-3-about {
		display: none;
	}

	.col-9-about {
		width: 100%;
	}

	.header-section h1 {
		font-size: 1.5em;
	}

	.tools-row {
		font-size: 1em;
	}
}

@media (max-width: 725px) {
	.grid-4 {
		display: grid;
		grid-template-columns: none;
		grid-gap: 1rem;
	}

	.flip-card-background-one img {
		max-width: 300px;
		max-height: 200px;
	}

	.flip-card {
		width: 300px;
		height: 200px;
	}

	.col-6-hide {
		display: none;
	}

	.header-button {
		font-size: 0.5em;
	}

	.sectionTitle-Services:after {
		font-size: 1em;
	}

	.sectionTitle-Projects:after {
		font-size: 1em;
	}

	.sectionTitle-Experience:after {
		font-size: 1em;
	}

	.button-section button {
		font-size: 0.7em;
	}

	.job-section h2 {
		font-size: 1.25em;
	}

	.job-section h3 {
		font-size: 0.7em;
	}

	.job-section p {
		font-size: 0.5em;
	}

	.jobdesc span {
		color: #16e0bd;
		font-size: 0.8em;
	}

	.project-stack {
		display: none;
	}

	.project-desc {
		display: none;
	}

	.project-link {
		margin: 0 15px;
	}

	.footer {
		font-size: 0.6em;
	}

	.footer-ul ul {
		margin: 0 auto;
	}

	.footer-ul li {
		width: 30px;
		height: 30px;
		font-size: 1.5em;
	}
}

@media (max-width: 620px) {
	.abouttitletwo {
		display: none;
	}

	.tools {
		display: none;
	}
}
